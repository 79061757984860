import { Box } from '@mui/material';
import { blue, common, grey, teal } from '@mui/material/colors';
import { esES as coreEsES } from '@mui/material/locale';
import { alpha, createTheme, StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { esES as dataGridEsES } from '@mui/x-data-grid';
import { esES as pickersEsES } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ThemeProvider from 'client/util/ThemeProvider';
import Confirm from 'components/Confirm/Confirm';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { setLocale } from '../../AppActions';
import favicon from '../../favicon.png';
import styles from './App.css';
import muiTheme from '../../muiTheme';

const blue100 = blue['100'];
const blue500 = blue['500'];
const blue700 = blue['700'];
const darkBlack = grey[900];
const fullBlack = common.black;
const grey100 = grey['100'];
const grey300 = grey['300'];
const grey400 = grey['400'];
const grey500 = grey['500'];
const white = common.white;

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const localeMatch = location.pathname.match(/^\/(e[sn])($|\/)/);
    if (localeMatch) {
      dispatch(setLocale(localeMatch[1]));
    }
  }, []);

  const { locale } = useIntl();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
        <title>Promoter Ninja</title>
        <link rel="shortcut icon" type="image/png" href={favicon}/>
        <body className={styles.body}/>
      </Helmet>
      <GoogleOAuthProvider clientId={process.env.GOOGLE_OAUTH_CLIENT_ID}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={muiTheme}>
              <ThemeProvider theme={muiTheme}>
                <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterMoment}>
                  <Outlet/>
                  <Confirm/>
                </LocalizationProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;

