import { Box } from '@mui/material';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import SVGInline from 'react-svg-inline';

import LayoutFocused from '../LayoutFocused/LayoutFocused';
import smokeBomb from './promoter-ninja-smoke-bomb.svg';

import styles from './SurveyDisabled.css';

const messages = defineMessages({
  title: {
    id: 'SurveyDisabled.Title',
    defaultMessage: 'Survey disabled',
  },
});

const SurveyDisabled = () => {
  const { formatMessage } = useIntl();
  return (
    <LayoutFocused
      maxWidth={600}
      className={styles.container}
      title={formatMessage(messages.title)}
    >
      <div>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          component="h3"
        >
          <FormattedMessage id="SurveyDisabled.Heading" defaultMessage="Survey Smoke Bomb!" />
          <SVGInline
            svg={smokeBomb}
            style={{ width: 43.5, height: 31.5, display: 'inline-block' }}
          />
        </Box>
        <div className={styles.body}>
          <div className={styles.alternatives}>
            <FormattedMessage
              id="SurveyDisabled.Subheading"
              defaultMessage="This survey has swiftly disappeared! Ready to harness the ninja secrets of engaging surveys? Channel your inner sensei with {link}."
              values={{
                link: <Link to="/">Promoter Ninja</Link>,
              }}
            />
          </div>
        </div>
      </div>
    </LayoutFocused>
  );
};

export default SurveyDisabled;
