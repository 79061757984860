import { Info } from '@mui/icons-material';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import SecondaryNavTabs from 'client/modules/Surveys/pages/DashboardPage/SecondaryNavTabs';
import Mustache from 'mustache';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import * as Config from '../../../../../server/config';
import AsyncCodeSnippet from '../../../App/components/CodeSnippet/AsyncCodeSnippet';
import Layout from '../../../App/components/Layout/LayoutContainer';
import { getToken, getUserWebId } from '../../../Auth/AuthReducer';
import {
  inputChange as inputChangeAction,
  setSurveySubmitEnabled,
  setWebSnippetStatus,
} from '../../SurveyActions';
import {
  canSubmit,
  getSurvey,
  getWebId,
  getWebSnippetActive,
  surveyMessages,
} from '../../SurveyReducer';
import snippet from './snippet.raw.html';
import styles from './WebSnippetPage.css';

const messages = defineMessages({
  saveChanges: {
    id: 'WebSnippetPage.saveChanges',
    defaultMessage: 'Save changes',
  },
  turnOff: {
    id: 'WebSnippetPage.turnOff',
    defaultMessage: 'Turn off',
  },
  turnOn: {
    id: 'WebSnippetPage.turnOn',
    defaultMessage: 'Turn on',
  },
  title: {
    id: 'WebSnippetPage.title',
    defaultMessage: 'Add the snippet to your site',
  },
  dontSend: {
    id: 'WebSnippetPage.dontSend',
    defaultMessage: "Don't send",
  },
  specific: {
    id: 'WebSnippetPage.specific',
    defaultMessage: 'This survey',
  },
  anySurvey: {
    id: 'WebSnippetPage.anySurvey',
    defaultMessage: 'Any survey',
  },
  useTargeting: {
    id: 'WebSnippetPage.useTargeting',
    defaultMessage:
      'Will show the first survey that matches the targeting conditions. {learnMore}.',
  },
  learnMore: {
    id: 'WebSnippetPage.learnMore',
    defaultMessage: 'Learn more',
  },
});

const WebSnippetPage = props => {
  const { id: idProp, setWebSnippetStatus: setStatus, token, active, webId, userWebId } = props;

  const [specificSurvey, setSpecificSurvey] = React.useState(true);
  const [renderedSnippet, setRenderedSnippet] = React.useState('');
  const { id: idParam } = useParams();
  const survey = useSelector(state => getSurvey(state, idProp || idParam)) || {};

  const id = idProp || idParam;

  const { formatMessage } = useIntl();

  let returnLinkTo = null;
  let returnLinkText = null;
  if (id) {
    returnLinkTo = `/surveys/${id}/import`;
    returnLinkText = formatMessage(surveyMessages.chooseADifferentChannel);
  }
  const { assetsRootUrl } = Config;
  useEffect(() => {
    const folder = !specificSurvey && { id: survey.folder || 'active' };
    const newRenderedSnippet = Mustache.render(snippet, {
      webId,
      assetsRootUrl,
      userWebId,
      specificSurvey,
      folder,
    });
    setRenderedSnippet(newRenderedSnippet);
  }, [webId, assetsRootUrl, userWebId, specificSurvey]);

  return (
    <Layout
      returnLinkTo={returnLinkTo}
      returnLinkText={returnLinkText}
      secondaryNav={<SecondaryNavTabs survey={id} />}
    >
      <Helmet title={formatMessage(messages.title)} />
      <Grid className={styles.mainGrid} fluid>
        <Row>
          <Col>
            <h1 className={styles.mainTitle}>{formatMessage(messages.title)}</h1>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col className={styles.description} xs={12} sm={7} md={8} lg={8}>
            <p>
              <strong className={styles.copyPaste}>
                <FormattedMessage
                  id="WebSnippetPage.copyAndPaste"
                  defaultMessage="Copy and paste this code snippet into your site."
                />
              </strong>
            </p>
            <p>
              <FormattedMessage
                id="WebSnippetPage.copyPasteDescription"
                defaultMessage={
                  ' You can add the snippet anywhere in the {head} or {body} section.' +
                  ' The snippet loads in the background, so it does not impact the performance of your site.'
                }
                values={{
                  head: <code>&lt;head&gt;</code>,
                  body: <code>&lt;body&gt;</code>,
                }}
              />
            </p>
            <Tabs value={specificSurvey ? 0 : 1}>
              <Tab
                label={formatMessage(messages.specific)}
                onClick={() => setSpecificSurvey(true)}
              />
              <Tab
                label={
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    {formatMessage(messages.anySurvey)}
                    <Tooltip
                      title={formatMessage(messages.useTargeting, {
                        learnMore: (
                          <a
                            href="https://www.promoter.ninja/help/distribution/show-a-survey-only-to-specific-audiences"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {formatMessage(messages.learnMore)}
                          </a>
                        ),
                      })}
                    >
                      <Info sx={{ fontSize: '1em' }} />
                    </Tooltip>
                  </Box>
                }
                onClick={() => setSpecificSurvey(false)}
              />
            </Tabs>
            <AsyncCodeSnippet
              id="code-snippet"
              className={styles.snippet}
              value={renderedSnippet}
            />
            <p>
              <FormattedMessage
                id="WebSnippetPage.readDocumentation"
                defaultMessage="For advanced options, please read the {apiLink}."
                values={{
                  apiLink: (
                    <a href="/apidocs/#api-Web-WebSnippet">
                      <FormattedMessage
                        id="WebSnippetPage.apiLink"
                        defaultMessage="full API documentation"
                      />
                    </a>
                  ),
                }}
              />
            </p>
            <h3>
              <FormattedMessage
                id="WebSnippetPage.testingSnippetTitle"
                defaultMessage="Testing your snippet"
              />
            </h3>
            <p>
              <FormattedMessage
                id="WebSnippetPage.testingSnippetText"
                defaultMessage="Add {urlParam} to your site’s URL to see the survey immediately."
                values={{
                  urlParam: <code>?promoterNinja=test</code>,
                }}
              />
            </p>
            {!active && (
              <div>
                <p>
                  <FormattedMessage
                    id="WebSnippetPage.turnOnDescription"
                    defaultMessage="Once you have added the snippet, click below to turn on."
                  />
                </p>
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={() => setStatus(token, id, true)}
                >
                  {formatMessage(messages.turnOn)}
                </Button>
              </div>
            )}
          </Col>
          <Col xs={12} sm={4} md={3} lg={3}>
            {active ? (
              <div>
                <hr />
                <h3>
                  <FormattedMessage
                    id="WebSnippetPage.turnOffTitle"
                    defaultMessage="Turn off Web"
                  />
                </h3>
                <p>
                  <FormattedMessage
                    id="WebSnippetPage.turnOffDescription"
                    defaultMessage="Immediately stop showing the survey on your website."
                  />
                </p>
                <Button
                  type="button"
                  style={{ color: '#e43300' }}
                  onClick={() => setStatus(token, id, false)}
                >
                  {formatMessage(messages.turnOff)}
                </Button>
              </div>
            ) : null}
            <hr />
            <h3>
              <FormattedMessage
                id="WebSnippetPage.customizeAppearanceTitle"
                defaultMessage="Customize appearance"
              />
            </h3>
            <p>
              <FormattedMessage
                id="WebSnippetPage.customizeAppearanceDescription"
                defaultMessage="Customize the aspect of the survey you will send to your customers"
              />
            </p>
            <Link to={`/surveys/${id}/edit`}>
              <FormattedMessage
                id="WebSnippetPage.customizeAppearanceLink"
                defaultMessage="Customize appearance"
              />
            </Link>
            <hr />
            <h3>
              <FormattedMessage id="WebSnippetPage.settingsTitle" defaultMessage="Settings" />
            </h3>
            <p>
              <FormattedMessage
                id="WebSnippetPage.settingsDescription"
                defaultMessage="Change sampling, delay on page, and automatic email triggering."
              />
            </p>
            <Link to={`/surveys/${id}/edit/targeting/web`}>
              <FormattedMessage
                id="WebSnippetPage.settingsLink"
                defaultMessage="Web sampling settings"
              />
            </Link>
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
};

WebSnippetPage.propTypes = {
  id: PropTypes.string.isRequired,
  userWebId: PropTypes.string.isRequired,
  setWebSnippetStatus: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  webId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  token: getToken(state),
  userWebId: getUserWebId(state),
  active: getWebSnippetActive(state),
  webId: getWebId(state),
  submitEnabled: canSubmit(state),
});

const mapDispatchToProps = dispatch => {
  return {
    setWebSnippetStatus: (token, id, status) => {
      dispatch(setWebSnippetStatus(token, id, status));
    },
    inputChange(id, value) {
      dispatch(setSurveySubmitEnabled(true));
      dispatch(inputChangeAction(id, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebSnippetPage);
