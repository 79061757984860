import { Box, Typography } from '@mui/material';
import classNames from 'classnames/bind';
import AdditionalQuestionForm from 'client/modules/Response/components/AdditionalQuestionForm/AdditionalQuestionForm';
import { addAdditionalQuestionResponse as addAdditionalQuestionResponseAction } from 'client/modules/Response/ResponseActions';
import { getNextQuestion as getNextQuestionAction } from 'client/modules/Surveys/SurveyActions';
import { getAdditionalQuestions, getId, getSurvey } from 'client/modules/Surveys/SurveyReducer';
import mergeDeep from 'client/util/mergeDeep';
import translateAdditionalQuestions from 'client/util/translateAdditionalQuestions';
import useActions from 'client/util/useActions';
import useStack from 'client/util/useStack';
import withParams from 'client/util/withParams';
import pick from 'lodash/pick';
// eslint-disable-next-line no-unused-vars
import * as Preact from 'preact';
import PropTypes from 'prop-types';
/** @jsx h */
import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import translations from './translations.svg';
import { localizationData } from '../../../../../Intl/setup';

import {
  getAdditionalQuestions as getResponseAdditionalQuestions,
  getBrand,
  getButtonColor,
  getLanguage,
  getLogoUrl,
  getTopColor,
  getFetchError,
} from '../../ResponseReducer';

import styles from './AddResponsePages.css';
import ErrorPage from '../../../App/components/ErrorPage/ErrorPage';

const messages = defineMessages({
  stopEditingToUsePreview: {
    id: 'AdditionalQuestionPage.stopEditingToUsePreview',
    defaultMessage: 'Stop editing to use preview',
  },
  questionNotAvailableInLanguage: {
    id: 'AdditionalQuestionPage.questionNotTranslatedToLanguage',
    defaultMessage:
      'This question has yet to be translated into this language. To do it now, please go to the {link}.',
  },
  translationsTab: {
    id: 'AdditionalQuestionPage.translationsTab',
    defaultMessage: 'Translations tab',
  },
  error: {
    id: 'AdditionalQuestionPage.error',
    defaultMessage: 'Error',
  },
});

const cx = classNames.bind(styles);

const AdditionalQuestionPage = props => {
  const { formatMessage } = useIntl();
  const additionalQuestionsRef = useRef();
  const {
    language,
    brand,
    logoUrl,
    buttonColor,
    topColor,
    rand,
    preview,
    question,
    hasNext,
    hasPrevious,
    surveyId,
    previewProps,
    error,
  } = props;
  const { editing, score, personProperties, setQuestionIndex } = previewProps;
  const isPreview = !!setQuestionIndex;
  const [values, setValues] = useState({});
  const answerStack = useStack();
  const { response, questionIndex: questionIndexParam } = useParams();
  const questionIndex = questionIndexParam || previewProps.questionIndex;
  const navigate = useNavigate();
  const addAdditionalQuestionResponse = useActions(addAdditionalQuestionResponseAction);
  const getNextQuestion = useActions(getNextQuestionAction);
  const emailMessages = localizationData[language].messages;
  const powered = emailMessages['nps.powered'].replace(
    '{promoter_ninja}',
    '<a target="_blank" rel="noopener noreferrer" href="https://www.promoter.ninja/">Promoter Ninja</a>',
  );
  const dir = ['ar', 'he'].indexOf(language) > -1 ? 'rtl' : 'ltr';
  const handleSubmit = async (id, value) => {
    const res = isPreview
      ? await getNextQuestion(surveyId, score, personProperties, language, id, value)
      : await addAdditionalQuestionResponse(response, id, value, preview ? surveyId : undefined);
    answerStack.push(+questionIndex);
    if (isPreview) {
      setQuestionIndex(res.nextQuestion);
    } else if (typeof res.nextQuestion === 'number' && res.nextQuestion >= 0) {
      navigate(`/response/${response}/q/${res.nextQuestion}`);
    } else {
      navigate(`/response/${response}/thanks`);
    }
  };
  const handlePrevious = () => {
    if (!answerStack.isEmpty) {
      const previousQuestionIdx = answerStack.pop();
      if (isPreview) {
        setQuestionIndex(previousQuestionIdx);
      } else {
        navigate(`/response/${response}/q/${previousQuestionIdx}`);
      }
    }
  };
  const setValue = q => value => {
    setValues(v => ({ ...v, [q]: value }));
  };
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const { h } = Preact;
    if (additionalQuestionsRef.current) {
      Preact.render(
        <AdditionalQuestionForm
          additionalQuestions={props.additionalQuestions}
          values={values}
          disabled={editing}
          onSubmit={handleSubmit}
          onPrevious={handlePrevious}
          question={question}
          dir={dir}
          language={language}
          hasNext={hasNext}
          hasPrevious={hasPrevious || !answerStack.isEmpty}
          buttonColor={buttonColor}
          value={values[question._id] || null}
          setValue={setValue(question._id)}
          messages={localizationData[language].messages}
          stopEditingMessage={formatMessage(messages.stopEditingToUsePreview)}
        />,
        additionalQuestionsRef.current,
      );
    }
  }, [question, values[question?._id], answerStack.peek]);

  // eslint-disable-next-line no-unused-vars
  const h = React.createElement;

  if (error) {
    return <ErrorPage />;
  }

  if (!question) {
    return (
      <div className={styles.notAvailable}>
        <Box sx={{ color: 'action.active' }}>
          <SVGInline svg={translations} />
        </Box>
        <Typography>
          {formatMessage(messages.questionNotAvailableInLanguage, {
            link: <Link to="../translations">{formatMessage(messages.translationsTab)}</Link>,
          })}
        </Typography>
      </div>
    );
  }

  return (
    <div className={cx('main', { preview })}>
      <div className={styles['survey-response']}>
        <div className={styles['survey-response-header']} style={{ borderColor: topColor }}>
          {logoUrl ? (
            <img alt={brand} className={styles.l12} src={`${logoUrl}?r=${rand}`} />
          ) : (
            <div className={styles.l11} dir={dir}>
              <span className="val-display-name">{brand}</span>
            </div>
          )}
        </div>
        <div className={styles['survey-response-body']}>
          <div className={styles['survey-response-body-inner']}>
            <div ref={additionalQuestionsRef} />
          </div>
        </div>
        <div
          dir={dir}
          className={styles['survey-response-footer']}
          dangerouslySetInnerHTML={{ __html: powered }}
        />
      </div>
    </div>
  );
};

// Specifies the default values for props:
AdditionalQuestionPage.defaultProps = {
  question: null,
  brand: '',
  topColor: '#333',
  buttonColor: '#333',
  language: 'en',
  logoUrl: null,
  preview: false,
  hasNext: false,
  hasPrevious: false,
  previewProps: {},
  surveyId: null,
  error: null,
};

AdditionalQuestionPage.propTypes = {
  hasNext: PropTypes.bool,
  hasPrevious: PropTypes.bool,
  question: PropTypes.object,
  brand: PropTypes.string,
  topColor: PropTypes.string,
  buttonColor: PropTypes.string,
  language: PropTypes.string,
  logoUrl: PropTypes.string,
  preview: PropTypes.bool,
  rand: PropTypes.number.isRequired,
  previewProps: PropTypes.shape({
    questionIndex: PropTypes.number,
    setQuestionIndex: PropTypes.func,
    score: PropTypes.number,
    personProperties: PropTypes.object,
    editing: PropTypes.bool,
  }),
  surveyId: PropTypes.string,
  additionalQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  const surveyId = getId(state);
  const survey = getSurvey(state, surveyId);
  let ret = {
    customizations: survey?.customizations,
    brand: getBrand(state),
    topColor: getTopColor(state),
    buttonColor: getButtonColor(state),
    language: getLanguage(state),
    logoUrl: getLogoUrl(state),
    rand: Math.random(),
    surveyId,
    error: getFetchError(state),
  };
  const fromOwnQuestion = pick(ownProps.question, Object.keys(ret));
  const fromSurvey = pick(survey, Object.keys(ret));
  mergeDeep(ret, fromOwnQuestion);
  mergeDeep(ret, fromSurvey);
  ret.additionalQuestions = getResponseAdditionalQuestions(state) || getAdditionalQuestions(state);
  if (survey) {
    ret = translateAdditionalQuestions(ret, ownProps.language || ret.language);
  }
  ret.language = ownProps.language || ret.language;
  ret.question =
    ownProps.question ||
    (typeof ownProps.params.questionIndex !== 'undefined'
      ? ret.additionalQuestions[+ownProps.params.questionIndex]
      : ret.additionalQuestions[+ownProps.questionIndex]);
  ret.hasNext =
    ownProps.hasNext || ownProps.params.questionIndex < ret.additionalQuestions.length - 1;
  ret.hasPrevious = ownProps.hasPrevious;
  return ret;
}

export default withParams(connect(mapStateToProps)(AdditionalQuestionPage));
