import { localizationData } from 'Intl/setup';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getBrand,
  getButtonColor,
  getLanguage,
  getLogoUrl,
  getTopColor,
} from '../../ResponseReducer';

import styles from './AddResponsePages.css';

const AlreadyRespondedPage = () => {
  const brand = useSelector(getBrand);
  const topColor = useSelector(getTopColor);
  const buttonColor = useSelector(getButtonColor);
  const logoUrl = useSelector(getLogoUrl);
  const language = useSelector(getLanguage);
  const dir = ['ar', 'he'].indexOf(language) > -1 ? 'rtl' : 'ltr';

  const emailMessages = localizationData[language].messages;
  const [prefix, suffix] = emailMessages['nps.powered'].split('{promoter_ninja}');

  return (
    <div className={styles.main} style={{ '--pn-button-color': buttonColor }}>
      <div className={styles['survey-response']}>
        <div className={styles['survey-response-header']} style={{ borderColor: topColor }}>
          {logoUrl ? (
            <img alt={brand} className={styles.l12} src={logoUrl} />
          ) : (
            <div className={styles.l11} dir={dir}>
              {brand}
            </div>
          )}
        </div>
        <div className={styles['survey-response-body']}>
          <div className={styles['survey-response-body-inner']}>
            <h1 className={styles.title}>{emailMessages.already_responded}</h1>
            <p>{emailMessages.only_once}</p>
          </div>
        </div>
        <div dir={dir} className={styles['survey-response-footer']}>
          {prefix}
          <a target="_blank" rel="noopener noreferrer" href="https://www.promoter.ninja/">
            Promoter Ninja
          </a>
          {suffix}
        </div>
      </div>
    </div>
  );
};

export default AlreadyRespondedPage;
